import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { ReactComponent as ChevronRight } from "../../assets/chevron-right.svg";
import { ReactComponent as ChevronLeft } from "../../assets/chevron-left.svg";
import { ReactComponent as CrossSmall } from "../../assets/cross-small.svg";
import { ReactComponent as IconPlus } from "../../assets/plus.svg";

import "./styles.scss";

export default function Button({
  cta,
  children,
  direction,
  className,
  remove,
  showFilter,
  active,
  ...props
}) {
  const classMode = cta ? "button--cta" : "button--default";
  const classDirection = `button--${direction}`;
  const removeTrue = `remove--${remove}`;
  const Filter = `showfilter--${showFilter}`;
  const activeTrue = `active--${active}`;

  return (
    <button
      type="button"
      className={[
        "button",
        classMode,
        classDirection,
        className,
        removeTrue,
        Filter,
        activeTrue,
      ]
        .join(" ")
        .trim()}
      {...props}
    >
      {direction === "back" && <ChevronLeft className="icon" />}
      {showFilter === true && <IconPlus className="icon" />}

      {children}
      {direction === "next" && <ChevronRight className="icon" />}
      {remove === true && <CrossSmall className="icon" />}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  cta: PropTypes.bool,
  direction: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  remove: PropTypes.bool,
  showFilter: PropTypes.bool,
  active: PropTypes.bool,
};

Button.defaultProps = {
  cta: false,
  onClick: undefined,
  direction: "next",
  remove: false,
  showFilter: false,
  active: false,
};
