import { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { getCertificates, searchCertificates } from "../../utils/certificates";

import SearchContext from "../../components/search";

import Option from "./Option";
import LoadingWrapper from "../../components/loading-wrapper";

/**
 * @param {array} exclude Array of strings. Options with name in this array shall be excluded
 * @returns {JSX.Element}
 * @constructor
 */
function Options({ exclude, startWithNumber, className }) {
  const { search } = useContext(SearchContext);

  function filterExclusions(options, exclude, startWithNumber) {
    if (!options) {
      return;
    }

    const regex = /^\d/;
    const result =
      exclude.length > 0
        ? options.filter((option) => {
            return !exclude.includes(option.title);
          })
        : options;

    return result
      .filter((item) =>
        startWithNumber ? regex.test(item.title) : !regex.test(item.title)
      )
      .sort((a, b) =>
        a.title.toLowerCase().localeCompare(b.title.toLowerCase())
      );
  }

  const { isLoading, data: certificates } = useQuery(["certificates"], () =>
    getCertificates()
  );

  return (
    <LoadingWrapper loading={isLoading}>
      {certificates &&
        filterExclusions(
          searchCertificates(certificates, search),
          exclude,
          startWithNumber
        ).map((item) => (
          <Option option={item} key={item.id} className={className} />
        ))}
    </LoadingWrapper>
  );
}

Options.defaultProps = {
  exclude: [],
};

export default Options;
