import { useQuery } from "@tanstack/react-query";
import Spacer from "../spacer";
import LoadingWrapper from "../loading-wrapper";
import React, { useEffect, useState } from "react";

import "./styles.scss";

import {
  getCertificates,
  getContactInformationByCounties,
} from "../../utils/certificates";

export default function Contact({ county, title }) {
  if (!county) {
    return (
      <p className="font-serif-text-regular">
        Velg fylke for å se kontaktinformasjon.
      </p>
    );
  }

  const { isLoading, data: certificates } = useQuery(
    ["certificates"],
    getCertificates
  );

  const [contacts, setContacts] = useState([""]);

  const getContactInfo = () => {
    const contactInformation = getContactInformationByCounties(
      certificates,
      county,
      title
    );
    setContacts(contactInformation);
  };

  useEffect(() => {
    getContactInfo();
  }, []);

  return (
    <>
      {contacts === undefined || contacts.length == 0 ? (
        <h4 className="contact-title font-serif-heading-small">
          Fylket har ikke lagt inn noen kontaktinformasjon.
        </h4>
      ) : (
        <h4 className="contact-title font-serif-heading-small">
          Kontaktinformasjon
        </h4>
      )}

      {county === "" ? (
        <p className="font-serif-text-regular">
          Velg fylke for å se kontaktinformasjon.
        </p>
      ) : (
        <></>
      )}

      <LoadingWrapper isLoading={isLoading}>
        {contacts &&
          contacts.map(({ epost, lenke, info, telefon }, index) => (
            <div className="contact" key={index}>
              <p>{info}</p>
              <Spacer />
              {telefon ? (
                <>
                  <h4 className="font-serif-text-regular font-bold">Telefon</h4>
                  <p className="font-serif-text-regular">{telefon}</p>
                </>
              ) : null}
              <Spacer />
              {epost ? (
                <>
                  <h4 className="font-serif-text-regular font-bold">Epost</h4>
                  <p className="font-serif-text-regular">
                    <a href="mailto:epost" target="_blank">
                      {epost}
                    </a>
                  </p>
                </>
              ) : null}
              <Spacer />
              {lenke ? (
                <>
                  <h4 className="font-serif-text-regular font-bold">
                    Nettsider
                  </h4>
                  <a
                    href={lenke}
                    target="_blank"
                    className="font-serif-text-regular"
                  >
                    {lenke}
                  </a>
                </>
              ) : null}
            </div>
          ))}
      </LoadingWrapper>
    </>
  );
}
