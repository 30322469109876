/**
 * allOptions
 * @module utils/options
 */

function normalizeOptionData(data) {
  const {
    short_id,
    tittel: title,
    beskrivelse: description,
    ingress: introtext,
    info: text,
    ordningslenke: link,
    fag: containedFag,
    vei: path,
    vei_beskrivelse: descriptionOfYears,
    vei_varighet: totalYears,
  } = data;

  return {
    short_id,
    title,
    description,
    introtext,
    text,
    link,
    containedFag,
    path,
    descriptionOfYears,
    totalYears,
  };
}

/**
 * Returns a structure of all the options to education
 *
 * @returns {object} a key-value object with all the options by id
 */
export async function getAllOptions() {
  if (!window.CONFIG_OPTION_SELECTOR) {
    console.error('API URL not defined.');
    return [];
  }

  const response = await fetch(`${window.CONFIG_OPTION_SELECTOR}`)
  const data = await response.json();
  const result = [];

  // Leave if no more results to parse
  if (!data || !data.length) {
    return result;
  }

  // Parse results into a normalized array
  data.forEach((item) => {
    const option = normalizeOptionData(item);
    return option && result.push(option);
  });

  return result;
}

/**
 * Returns a structure of options to education
 *
 * @param {array} options the options list
 * @param {object} obj the filter object
 *
 * @returns {object} a key-value object with all the options by id
 */
export function getOptions(options, {
  occupations,
}) {
  let results = [];

  // Filter occupations
  results = options.filter((option) => {
    // No occupations to filter.
    if (occupations.length === 0) {
      return true;
    }

    // Check if there's any occupation id in filter that matches any id on containedFag.
    const filterIds = occupations
      .map((o) => o.id)
      .filter((id) => option.containedFag && option.containedFag.includes(id));

    return filterIds.length > 0;
  });

  return results;
}
