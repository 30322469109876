import React, { useContext } from "react";
import Section from "../components/section";
import PageIntroduction from "../components/page-introduction";
import Tooltip from "../components/tooltip";
import SearchOccupation from "../components/search-occupation";
import Spacer from "../components/spacer";
import Button from "../components/button";
import LoadingWrapper from "../components/loading-wrapper";
import Select from "../components/input/select";
import {
  getCertificateCommunesByCounty,
  getCertificateCounties,
  getCertificates,
} from "../utils/certificates";
import SpeechBubble from "../components/speechBubble";
import Filter from "../components/filtertoggle";
import Radio from "../components/input/radio";
import SearchContext from "../components/search";
import { useQuery } from "@tanstack/react-query";

const Sidebar = () => {
  const { search, setSearch } = useContext(SearchContext);
  // const [counties, setCounties] = useState([]);

  function setFilter(filter, value) {
    if (filter === "choosenCounty" && value === "Vis alle") {
      value = null;
    }

    if (filter === "choosenCommune" && value === "Vis alle kommuner") {
      value = null;
    }

    search[filter] = value;
    setSearch({ ...search });
  }

  function toggleSearchCounty(county, checked) {
    if (checked) {
      search.counties.push(county);
    } else {
      const index = search.counties.indexOf(county);
      search.counties.splice(index, 1);
    }
    setSearch({ ...search });
  }

  function getCounties(county, checked) {
    if (checked) {
      search.counties.push(county);
    } else {
      const index = search.counties.indexOf(county);
      search.counties.splice(index, 1);
    }
    setSearch({ ...search });
  }

  function removeSearchOccupation(id) {
    const filtered = search.occupations.filter((occupation) => {
      return occupation.id !== id;
    });

    setSearch({ ...search, occupations: filtered });
  }

  // const { isLoading, error, data: counties, isFetching } = useQuery(["counties"], getCertificateCounties);
  const {
    isLoading,
    error,
    data: certificates,
    isFetching,
  } = useQuery(["certificates"], getCertificates);
  return (
    <div className="aside">
      <Section key="pageintro">
        <div className="lg-down">
          <PageIntroduction />
        </div>

        <Tooltip
          title="Velg fag- eller svennebrev:"
          description="Velg fag- eller svennebrevet som interesserer deg. Vi viser deg hvilke veier som finnes."
        >
          <p className="text-md font-serif">Velg fag- eller svennebrev:</p>
        </Tooltip>

        <SearchOccupation />
        <Spacer />
        {search.occupations &&
          search.occupations.map((occupation) => (
            <>
              <Button
                key={occupation.id}
                remove={true}
                cta={false}
                direction="none"
                onClick={() => removeSearchOccupation(occupation.id)}
              >
                {occupation.name}
              </Button>
              <Spacer medium />
            </>
          ))}
        <Spacer small />

        <Tooltip
          title="Velg fylke eller kommune:"
          description="Når du velger hvilket fylke eller hvilken kommune du bor i, får du kun opp de veiene til fagbrev som er aktuelle for deg. Bare fylker og kommuner som har lagt inn egen informasjon, vil vises her."
        >
          <p className="text-md font-serif">Velg fylke eller kommune:</p>
        </Tooltip>

        <LoadingWrapper condition={isLoading}>
          {certificates && (
            <Select
              name="select"
              id="field-select-list"
              options={[
                "Vis alle",
                ...getCertificateCounties(certificates),
              ].map((county) => ({
                label: county,
                value: county,
              }))}
              value={search.choosenCounty || ""}
              placeholder="Søk etter fag- eller svennebrev"
              label="Velg Fylke eller kommune"
              setValue={(value) => {
                setFilter("choosenCounty", value);
                setFilter("choosenCommune", "");
              }}
            ></Select>
          )}
        </LoadingWrapper>

        {certificates && search.choosenCounty && (
          <Select
            name="select"
            id="field-select-list"
            options={[
              "Vis alle kommuner",
              ...getCertificateCommunesByCounty(
                certificates,
                search.choosenCounty
              ),
            ].map((commune) => ({
              label: commune,
              value: commune,
            }))}
            value={search.choosenCommune || ""}
            placeholder="Søk etter fag- eller svennebrev"
            label="Velg Fylke eller kommune"
            setValue={(value) => setFilter("choosenCommune", value)}
          ></Select>
        )}

        <Spacer large />

        <SpeechBubble className="mb-md">
          <p>
            Velg fagbrev eller fylke og svar på spørsmålene under, for å se
            ordningene som gjelder for deg.
          </p>
        </SpeechBubble>
        <Spacer large />
        <Filter open={true}>
          <Spacer large />

          <Tooltip
            title="Er du 25 år eller eldre?"
            description="Hvis du er over 25 år, kan det være noen veier til fagbrev som er mer aktuelle for deg."
          >
            <p className="text-xl font-serif">Er du 25 år eller eldre?</p>
          </Tooltip>

          <Radio
            className="subject-display__input"
            name="is-twenty-five"
            selected={search.isTwentyFive}
            setRadio={(value) => setFilter("isTwentyFive", value)}
            options={[
              { label: "Ja", value: "true" },
              { label: "Nei", value: "false" },
            ]}
          />
          <Spacer large />
          <Tooltip
            title="Har du erfaring fra yrket?"
            description="Noen veier til fagbrev krever at du har relevant erfaring fra yrket du vil ta fagbrev i. F.eks. at du har jobbet lenge som pleiemedarbeider, og nå vil ta fagbrev som helsefagarbeider."
          >
            <p className="text-xl font-serif">Har du erfaring fra yrket?</p>
          </Tooltip>
          {/*<Tooltip
              title="Har du erfaring fra yrket?"
              description="<p>Hvis du har fullført og bestått videregående, kan det hende du må betale for det nye fagbrevet selv.</p>

                <p>Du kan likevel få tilbud om videregående opplæring, hvis det er ledig plass. Da vil du havne sist i køen ved konkurranse om inntak.</p>

                <p>Det finnes også flere veier som du kan lese om på denne siden. Se f.eks. Fagbrev på jobb og 0+4-ordningen.</p>"
            >
              <p className="text-xl font-serif">Har du erfaring fra yrket?</p>
            </Tooltip>*/}
          <Radio
            className="subject-display__input"
            name="has-experience"
            selected={search.hasCertificate}
            setRadio={(value) => setFilter("hasCertificate", value)}
            options={[
              { label: "Ja", value: "true" },
              { label: "Nei", value: "false" },
            ]}
          />

          <Spacer large />
          <Tooltip
            title="Har du fag- eller svennebrev fra før?"
            description="Da har du brukt opp retten din til videregående, men det kan likevel finnes muligheter."
          >
            <p className="text-xl font-serif">
              Har du fag- eller svennebrev fra før?
            </p>
          </Tooltip>
          <Radio
            className="subject-display__input"
            name="has-certificate"
            selected={search.hasExperience}
            setRadio={(value) => setFilter("hasExperience", value)}
            options={[
              { label: "Ja", value: "true" },
              { label: "Nei", value: "false" },
            ]}
          />

          {search.hasExperience === "true" ? (
            <>
              <p className="text-lg font-normal">
                I Norge har vi noe som heter voksenrett. Det betyr at alle over
                25 år som ikke har fullført vidergående opplæring i Norge, har
                rett til tilbud om videregående opplæring. Det betyr at hvis du
                har et fagrev fra før, er det ikke sikkert at ditt fylke kan
                tilby deg gratis videregående opplæring.
              </p>
              <p className="text-lg font-normal">
                Du kan likevel få tilbud om videregående opplæring uten rett
                hvis det er ledig plass. Ved konkurranse om inntak vil du havne
                sist i køen.
              </p>
              <Spacer />
            </>
          ) : (
            <></>
          )}
          <Spacer large />

          <Tooltip
            title="Har du tatt videregående i utlandet?"
            description="Hvis du har tatt videregående  i utlandet, må du sjekke at den er godkjent i Norge."
          >
            <p className="text-xl font-serif">
              Har du tatt videregående i utlandet?
            </p>
          </Tooltip>
          {/*<Tooltip
              title="Har du tatt videregående i utlandet?"
              description="<p>Hvis du har tatt videregående  i utlandet, må du sjekke at den er godkjent i Norge.</p>

              <p>For at din videregående opplæring fra utlandet skal bli godkjent i Norge, må du ha:</p>

              <ul>
                <li>det som tilsvarer generell studiekompetanse i Norge</li>
                <li>dokumentasjon på norsk- og engelskkunnskapene dine</li>
                <li>eventuell annen dokumentasjon</li>
              </ul>

              <p>I de fleste yrkesfag-jobber er det opp til arbeidsgiver å vurdere om utdanningen din fra utlandet er god nok til å utføre jobben du søker. Det finnes noen unntak.</p>"
            >
              <p className="text-xl font-serif">Har du tatt videregående i utlandet?</p>
            </Tooltip>*/}
          <Radio
            className="subject-display__input"
            name="has-abroad-education"
            selected={search.hasAbroadEducation}
            setRadio={(value) => setFilter("hasAbroadEducation", value)}
            options={[
              { label: "Ja", value: "true" },
              { label: "Nei", value: "false" },
            ]}
          />

          {search.hasAbroadEducation === "true" ? (
            <>
              <p className="text-lg font-normal">
                Hvis du har tatt videregående opplæring i utlandet, må du sjekke
                at den er godkjent i Norge.
              </p>
              <p className="text-lg font-normal">
                For at din videregående utdanning fra utlandet skal bli godkjent
                i Norge, må du ha:
              </p>
              <ul>
                <li className="text-lg font-normal">
                  Det som tilsvarer generell studiekompetanse i Norge.
                </li>
                <li className="text-lg font-normal">
                  Dokumentasjon på norsk- og engelskkunnskapene dine.
                </li>
              </ul>
              <Button
                onClick={() =>
                  window.open(
                    "https://utdanning.no/tema/hjelp_og_veiledning/godkjenning_av_utenlandsk_videregaende_utdanning"
                  )
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Les mer om godkjenning av utenlandsk opplæring
              </Button>

              <Spacer />
            </>
          ) : (
            <></>
          )}
        </Filter>
      </Section>
    </div>
  );
};

export default Sidebar;
