import React from 'react';
import PropTypes from 'prop-types';
import { ThreeDots } from  'react-loader-spinner';

import './styles.scss';

export default function LoadingWrapper({ loading, children }) {
  return (
    <>
      {loading && (
        <ThreeDots 
          height="80" 
          width="80" 
          radius="9"
          color="#f89330" 
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName="loading-wrapper"
          visible={true}
        />

      )}

      {!loading && (
        <>
          { children }
        </>
      )}
    </>
  );
}


LoadingWrapper.propTypes = {
  loading: PropTypes.bool,
};

LoadingWrapper.defaultProps = {
  loading: false,
};
