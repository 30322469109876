import React, { useState, useMemo } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Alert } from "antd";
import queryString from "query-string";

import VeienTilFagbrev from "./veientilfagbrev";
import LandingPage from "./landingpage";
import Examples from "./examples";
import SearchContext from "../components/search";
import SecretPreview from "./secret-preview/SecretPreview";

import "./styles.scss";


function App() {
  const [search, setSearch] = useState({
    occupations: [],
    counties: [],
    erTjuefem: "",
    harErfaring: "",
    hasAbroadEducation: "",
    valgtFylke: "",
    valgtKommune: "",
  });

  const value = useMemo(() => ({ search, setSearch }), [search]);

  return (
    <SearchContext.Provider value={value}>
      <div className="x-wrapper">
        <HashRouter>
          <main>
            <Switch>
              <Route exact path="/">
                <LandingPage/>
              </Route>
              <Route exact path="/filter">
                <Alert
                  message="Tjenesten er under videreutvikling"
                  description="Vi jobber med å få alle fylkene til å legge inn informasjon."
                  type="warning"
                  showIcon
                  closable
                  className="warning-box-style icon-override close-icon-override text-override description-override"
                />
                <VeienTilFagbrev/>
              </Route>
              <Route path="/examples">
                <Examples />
              </Route>
              <Route path="/preview/:uuid/:rid?">
                <SecretPreview />
              </Route>
            </Switch>
          </main>
        </HashRouter>
      </div>
    </SearchContext.Provider>
  );
}

export default App;
