import { useQuery } from "@tanstack/react-query";
import LoadingWrapper from "../loading-wrapper";

import {
  getCertificates,
  getCertificatesByCounties,
} from "../../utils/certificates";

export default function CustomName({county}) {
  const { isLoading, data: certificates } = useQuery(["certificates"], getCertificates);

  return (
    <>
      {certificates && getCertificatesByCounties(certificates, [county]).map(({ customName, contact }, index) => (
        customName && (
          <div key={index} className="text-xl">
            {customName}
          </div>
        )
      ))}
    </>
  );
}
