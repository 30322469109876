import React from "react";
import Spacer from "../spacer";

function PageIntroduction() {
  return (
    <>
      <h1 className="font-serif-heading-large">Veien til fagbrev</h1>
      <Spacer small />
      <p className="font-serif-text-large">
        Se alle de ulike måtene du kan få&nbsp;
        <a
          className="font-serif-text-large"
          href="https://utdanning.no/tema/hjelp_og_veiledning/videregaende_som_voksen"
          target="_blank"
          rel="noopener noreferrer"
        >
          fag- eller svennebrev
        </a>
        &nbsp;på.
      </p>
      <Spacer small />
    </>
  );
}

export default PageIntroduction;
