/**
 * Occupations
 * @module utils/occupations
 */

function normalizeOccupationData(data) {
  const {
    programomradekode10: id,
    name,
    url,
    utdanningsprogram_kode: code,
  } = data;

  return {
    id,
    name,
    url,
    code,
  };
}

/**
 * Returns a structure with all the occupations
 *
 * @returns {object} a key-value object with all the occupations by id
 */
export async function getOccupations() {
  if (!window.CONFIG_SUBJECT_SELECTOR) {
    console.error('API URL not defined.');
    return [];
  }

  const response = await fetch(`${window.CONFIG_SUBJECT_SELECTOR}`)
  const data = await response.json();
  const result = [];

  // Leave if no more results to parse
  if (!data || !data.length) {
    return result;
  }

  // Parse results into a normalized array
  data.forEach((item) => {
    const subject = normalizeOccupationData(item);
    return subject && result.push(subject);
  });

  return result;
}

/**
 * Searches the occupations for a given string.
 * Checks for matches in the certificate ID and title.
 *
 * @param {array} occupations the occupations list
 * @param {string} str the input string
 * @returns {array} the list of results
 */
export function searchOccupations(occupations, str) {
  // const occupations = await getOccupations();
  const xstr = str.toLowerCase();
  return Object.values(occupations).filter(
    (s) =>
      (s.id + '').toLowerCase().includes(xstr) || s.name.toLowerCase().includes(xstr)
  );
}
