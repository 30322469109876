import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

export default function Section({
  width,
  Element,
  children,
  className,
  ...props
}) {
  let widthClass = "section__row";

  switch (width) {
    case "wide":
      widthClass += "--wide";
      break;
    case "narrow":
      widthClass += "--narrow";
      break;
    case "wideborder":
      widthClass += "--wideborder";
      break;
    default:
  }

  return (
    <Element className={["section", className].join(" ").trim()} {...props}>
      <div className={widthClass}>{children}</div>
    </Element>
  );
}

Section.propTypes = {
  Element: PropTypes.oneOf(["div", "section"]),
  width: PropTypes.oneOf(["", "wide", "narrow", "wideborder"]),
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
};

Section.defaultProps = {
  Element: "div",
  width: "",
};
