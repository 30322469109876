import React from 'react';
import { ReactComponent as CircularLoader } from "../../assets/circular-loader.svg";

const Loader = () => {
  return (
    <div className='secret-preview__loader'>
      <p className="font-serif text-lg">
        Her er en beskrivelse av ulike veier til fag- og svennebrev
      </p>

      <CircularLoader className="icon"/>
    </div>
  );
};

export default Loader;
