import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Input from "../input/input";
import LoadingWrapper from "../../components/loading-wrapper";

import "./styles.scss";

function HighlightTerm({ item, term }) {
  const regex = new RegExp(`(${term})`, "gi");
  const highlighted = item.name.replace(regex, (match, p) => {
    return `<em>${p}</em>`;
  });

  return <span dangerouslySetInnerHTML={{ __html: highlighted }}></span>;
}

function SearchAutocompleteResults({
  term,
  searchCallback,
  onSelectItem,
  onSetSelected,
  selected,
}) {
  if (!term) {
    return <></>;
  }

  const { isLoading, data: results } = useQuery(
    ["search-autocomplete-results", term],
    () => searchCallback(term)
  );

  return (
    <>
      <div className="search-autocomplete__results">
        <LoadingWrapper loading={isLoading}>
          {results && (
            <ul>
              {Object.keys(results).length !=0 ?results.map((item, index) => (
                <li
                  key={item.id}
                  className={index === selected ? "selected" : ""}
                >
                  <a
                    onFocus={(e) => onSetSelected(index)}
                    onClick={(e) => onSelectItem(item)}
                  >
                    <HighlightTerm item={item} term={term} />
                  </a>
                </li>
              )):<li><a>Ingen treff</a></li>}
            </ul>
          )}
        </LoadingWrapper>
      </div>
    </>
  );
}

let autocompleteTimeout;

export default function SearchAutocomplete({
  search: searchCallback,
  onSelect,
  value,
  ...props
}) {
  const [results, setResults] = useState([]);
  const [selected, setSelected] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [term, setTerm] = useState("");

  function autocomplete(term) {
    clearTimeout(autocompleteTimeout);
    setTerm(term);
    autocompleteTimeout = setTimeout(() => {
      setSearchTerm(term);
    }, 200);
  }

  function selectItem(item) {
    onSelect && onSelect(item);
    resetResults();
  }

  function resetResults() {
    setSearchTerm("");
    setResults([]);
    setTerm("")
    setSelected(0);
  }

  function handleKeyDown(ev) {
    if (ev.key === "Enter") {
      results && results.length > 0 && selectItem(results[selected]);
    }

    if (ev.key === "ArrowDown") {
      selected < results.length - 1 && setSelected(selected + 1);
    }

    if (ev.key === "ArrowUp") {
      selected > 0 && setSelected(selected - 1);
    }

    if (ev.key === "escape") {
      resetResults();
    }
  }

  return (
    <div className="search-autocomplete">
      <Input
        name="search"
        label="Søk"
        labelposition="hidden"
        className="search"
        placeholder="Søk etter fag- eller svennebrev"
        type="search"
        autoComplete="off"
        value={term}
        onInput={(e) => autocomplete(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
      />
      <SearchAutocompleteResults
        term={searchTerm}
        searchCallback={searchCallback}
        onSelectItem={selectItem}
        onSetSelected={setSelected}
        selected={selected}
      />
      {/* {results && results.length > 0 && (
        <div className="search-autocomplete__results">
          <ul>
            {results.map((item, index) => (
              <li key={item.id} className={index===selected?'selected':''}>
                <a href="#" onFocus={e => setSelected(index)} onClick={e => selectItem(item)}><span dangerouslySetInnerHTML={{__html: item.searchMatch}}></span></a>
              </li>
            ))}
          </ul>
        </div>
      )} */}
    </div>
  );
}
