import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import ReactTooltip from "react-tooltip";
import { useLocation } from 'react-router-dom'
import queryString from "query-string";



import {
  getCertificates,
  getCertificateCounties,
  getCertificateCommunesByCounty,
} from "../../utils/certificates";
import { getOccupations,searchOccupations } from "../../utils/occupations";

import Section from "../../components/section";
import Filter from "../../components/filtertoggle";
import { Select } from "@utdanningno/designsystem-react-select";
import Radio from "../../components/input/radio";
import SpeechBubble from "../../components/speechBubble";
import Spacer from "../../components/spacer";
import SearchOccupation from "../../components/search-occupation";
import PageIntroduction from "../../components/page-introduction";
import Button from "../../components/button";
import LoadingWrapper from "../../components/loading-wrapper";
import ExpandableField from "../../components/expandable-field";
import { ReactComponent as IconQuestionCircle } from "../../assets/question-circle.svg";
import { ReactComponent as WomanFindingError } from "../../assets/woman-finding-error.svg";


import SearchContext from "../../components/search";

import "@utdanningno/designsystem-react-select/designsystem-react-select.css";
import Results from "./results";
import "../../sass/style.scss";


function VeienTilFagbrev({ ...props }) {
  const [selectedCountyOption, setSelectedCountyOption] = useState(null);
  const [selectedCommuneOption, setSelectedCommuneOption] = useState(null);
  const { search, setSearch } = useContext(SearchContext);
  
  async function fetchURL(){
    const occupations = await getOccupations();
    if(window.location.hash.includes("?")){
      const url = window.location.hash.split("?")[1];
      const list = queryString.parse(url);
      let filtered =[];
      let veier =[];
      Object.entries(list).map(([key, value])=>{
        if(key == "fag"){
          if(typeof value == "string"){
            const result = searchOccupations(occupations, value)
            while(result[0] == undefined){
              1==0??console.log("X");
            }
            filtered.push(result[0])
          }
          else{
            Object.entries(value).map(([key,value])=>{
            const result = searchOccupations(occupations, value.toString())
            while(result[0] == undefined){
              1==0??console.log("X");
            }
            filtered.push(result[0])
          })
          }
          
        }
        else if(key == "vei" && value!=undefined){
          if(typeof value == "string"){
            veier.push(value)
          }
          else{
            Object.entries(value).map(([key,value])=>{
              veier.push(value)
            })
          }
        }
        else{
          setFilter(key,value)
        }
      })
      setFilter("vei", veier)
      setFilter("occupations", filtered)
    }
  } 
  
  
  
  

  function updateURL(id = ""){
    const params = new URLSearchParams();
    if(id != "Alle"){
      Object.entries(search).map(([key,value])=>{
        if(typeof value === 'string' && value != (undefined || null || "")){
          params.append(key, value);
        }
        else if(typeof value === 'object' && value != null &&value != undefined && value!= ""){
          if(key == "occupations"){
            Object.entries(value).map(([key,value])=>{
            if(value!=undefined && value.id != id){
              params.append("fag", value.name)
            }
          })}
          else if(key == "vei")Object.entries(value).map(([key,value])=>{
            if(value!=undefined){
              params.append("vei", value)
            }
          })
        }
      })
      
    }
    window.location.hash = `filter?${params.toString()}`;
  }

  function setFilter(filter, value, refresh = true) {
    if (filter === "valgtFylke" && value === "Velg fylke") {
      value = null;
    }else if (filter === "valgtKommune" && value === "Vis alle kommuner") {
      value = null;
    }
    search[filter] = value;
    setSearch({ ...search });
    refresh&&updateURL();
  
  }
  
  
  
  

  function removeSearchOccupation(id) {
    const filtered = search.occupations.filter(occupation => 
      occupation.id != id
    );
    setSearch({ ...search, occupations: filtered});
    updateURL(id);
  }

  function emptySearchOccupation(id) {
    const filtered = []
    setSearch({ ...search, occupations: filtered });
    updateURL("Alle")
  }

  function emptyFilter() {
    setFilter("valgtFylke","Velg fylke", false);
    setFilter("erTjuefem","", false);
    setFilter("harErfaring","", false);
    

    if(search.occupations) {
      for(var occupation of search.occupations) {
        emptySearchOccupation(occupation.id)
      }
    }
}

  const { isLoading, data: certificates } = useQuery(
    ["certificates"],
    getCertificates
  );

  // Array of counties that don't have any data yet.
  const countiesWithNoData = [
    "Nordland",
    "Vestland",
    "Rogaland",
    "Agder",
    "Viken",
    "Oslo",
  ];

  // The `countiesWithNoData` array is filtered to only include counties that are not already present in the `getCertificateCounties(certificates)` function,
  // and then those counties are added to the `uniqueCounties` array using the spread operator.
  // The uniqueCounties function will make sure that the data from certificate is being used.
  // When all counties have data, the `countiesWithNoData` array can be removed.
  const uniqueCounties = [
    ...getCertificateCounties(certificates),
    ...countiesWithNoData.filter(
      (county) => !getCertificateCounties(certificates).includes(county)
    ),
  ];

  const handleSelectCountyChange = (value) => {
    setSelectedCountyOption(value);
    setFilter("valgtFylke", value.value);
    setFilter("valgtKommune", "");
  };

  const handleSelectCommuneChange = (value) => {
    setSelectedCommuneOption(value);
    setFilter("valgtKommune", value.value);
  };

  const defaultOption = {
    label: "Velg fylke",
    value: "",
  };

  useEffect(()=>{
    fetchURL();
  },[])

  return (
    <div className="page-layout">
      <div className="main" aria-label="Hovedinnhold">
        {/* This conditional can be removed when all counties have data */}
        {search.valgtFylke &&
        !getCertificateCounties(certificates).includes(search.valgtFylke) ? (
          <Section key="pageintro">
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h1 className="font-serif-heading-large">Veien til fagbrev</h1>
                <p className="font-serif-text-large">
                  <b>{search.valgtFylke}</b> har ikke lagt inn egen
                  informasjon enda.
                </p>
                <p className="font-serif-text-large">
                  De nasjonale veiene gjelder for alle, uansett hvor du bor.
                </p>
                <Button
                  direction="next"
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    setFilter("valgtFylke", "");
                    updateURL();
                    setSelectedCountyOption(null);
                  }}
                >
                  Vis meg de nasjonale veiene
                </Button>
              </div>
              <WomanFindingError
                style={{
                  position: "relative",
                  marginRight: "-17px",
                }}
              />
            </div>
          </Section>
        ) : (
          <Results {...props} />
        )}
      </div>
      <div className="aside" aria-label="Filtrering av hovedinnhold">
        <Section key="pageintro">
          <div className="lg-down">
            <PageIntroduction />
          </div>

          <SearchOccupation />
          <Spacer />
          {search.occupations &&
            search.occupations.map((occupation) => (
              <>
                <Button
                  key={occupation.id}
                  remove={true}
                  cta={false}
                  direction="none"
                  onClick={() => removeSearchOccupation(occupation.id)}
                >
                  {occupation.name}
                </Button>
                <Spacer medium />
              </>
            ))}
          <Spacer small />

          <LoadingWrapper condition={isLoading}>
            {certificates && (
              <Select
                className="my-select"
                value={selectedCountyOption}
                name="select"
                id="field-select-list"
                options={[
                  {
                    options: [
                      defaultOption,
                      ...getCertificateCounties(certificates).map((county) => ({
                        label: county,
                        value: county,
                      })),
                    ],
                  },
                  {
                    options: uniqueCounties
                      .filter(
                        (county) =>
                          !getCertificateCounties(certificates).includes(county)
                      )
                      .map((county) => ({ label: county, value: county })),
                  },
                ]}
                placeholder={search.valgtFylke || defaultOption.label}
                onChange={handleSelectCountyChange}
                openMenuOnFocus
              />
            )}
          </LoadingWrapper>
          <Spacer medium />
          {certificates && search.valgtFylke && (
            <Select
              value={selectedCommuneOption}
              name="select"
              id="field-select-list"
              options={[
                "Vis alle kommuner",
                ...getCertificateCommunesByCounty(
                  certificates,
                  search.valgtFylke
                ),
              ].map((commune) => ({
                label: commune,
                value: commune,
              }))}
              placeholder={search.valgtKommune || "Vis alle kommuner"}
              onChange={handleSelectCommuneChange}
              openMenuOnFocus
            />
          )}

          <Spacer large />

          <SpeechBubble className="mb-md">
            <p className="font-serif-text-regular">
              Velg fagbrev eller fylke og svar på spørsmålene under, for å se
              ordningene som gjelder for deg.
            </p>
          </SpeechBubble>
          <Spacer large />
          <Filter open={true}>
            <Spacer large />
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <p
                className="font-serif-text-regular"
                style={{ whiteSpace: "nowrap" }}
                id="25"
              >
                Er du 25 år eller eldre?
              </p>
              <div style={{ alignSelf: "flex-start", marginTop: "0.2rem" }}>
                <IconQuestionCircle
                  data-tip
                  data-for="Er du 25 år eller eldre?"
                  className="tooltip-label__toggle"
                />
              </div>
              <ReactTooltip
                id="Er du 25 år eller eldre?"
                effect="solid"
                className="tooltipShow"
              >
                <h4>Er du 25 år eller eldre?</h4>
                <p>
                  Hvis du er over 25 år, kan det være noen veier til fagbrev som
                  er mer aktuelle for deg.
                </p>
              </ReactTooltip>
            </div>
            <Radio
              className="subject-display__input"
              name="is-twenty-five"
              selected={search.erTjuefem}
              setRadio={(value) => setFilter("erTjuefem", value)}
              labelledby={"25"}
              options={[
                { label: "Ja", value: "ja" },
                { label: "Nei", value: "nei" },
              ]}
            />
            <Spacer large />
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <p id="erf" className="font-serif-text-regular">
                Har du erfaring fra yrket?
              </p>
              <div style={{ alignSelf: "flex-start", marginTop: "0.1rem" }}>
                <IconQuestionCircle
                  data-tip
                  data-for="Har du erfaring fra yrket?"
                  className="tooltip-label__toggle"
                />
              </div>
              <ReactTooltip
                id="Har du erfaring fra yrket?"
                effect="solid"
                className="tooltipShow"
              >
                <h4>Har du erfaring fra yrket?</h4>
                <p>
                  Noen veier til fagbrev krever at du har relevant erfaring fra
                  yrket du vil ta fagbrev i.
                </p>
                <p>
                  F.eks. at du har jobbet lenge som pleiemedarbeider, og nå vil
                  ta fagbrev som helsefagarbeider.
                </p>
              </ReactTooltip>
            </div>
            <Radio
              className="subject-display__input"
              name="has-experience"
              labelledby={"erf"}
              selected={search.harErfaring}
              setRadio={(value) => setFilter("harErfaring", value)}
              options={[
                { label: "Ja", value: "ja" },
                { label: "Nei", value: "nei" },
              ]}
            />
            <Spacer medium />
             <button className="empty-filter-button font-serif-text-regular" onClick={() => emptyFilter()}>Tøm filter</button>
            <Spacer large />
            <h3 className="font-serif-heading-medium">Informasjon</h3>
            <div
              style={{
                display: "flex",
                marginRight: "4rem",
              }}
            >
              <ExpandableField 
                label="Har du fagbrev fra før?"
                id="tidligereFagbrev"
                style={{ borderTop: "none" }}
                className="font-serif-text-regular"
              >
                <p className="font-serif-text-regular">
                  I Norge har vi
                  <a
                    className="font-serif-text-regular"
                    href="https://utdanning.no/tema/hjelp_og_veiledning/videregaende_som_voksen"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &nbsp;noe som heter voksenrett.
                  </a>
                  &nbsp;Det betyr at alle over 25 år som ikke har fullført
                  videregående opplæring i Norge, har rett til tilbud om det.
                  Det betyr at hvis du har et fagbrev fra før, er det ikke
                  sikkert at ditt fylke kan tilby deg gratis videregående
                  opplæring.
                </p>
                <p className="font-serif-text-regular">
                  Du kan likevel få tilbud uten rett, hvis det er ledig plass.
                  Ved konkurranse om inntak vil du havne sist i køen når du har
                  fagbrev fra før.
                </p>
              </ExpandableField>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <ExpandableField
                id="videregåendeUtlandet"
                label="Gikk du videregaende i utlandet?"
                className="font-serif-text-regular"
              >
                <p className="font-serif-text-regular">
                  Hvis du har tatt videregående opplæring i utlandet, må du
                  sjekke at den er godkjent i Norge.
                </p>
                <p className="font-serif-text-regular">
                  For at din videregående utdanning fra utlandet skal bli
                  godkjent i Norge, må du ha:
                </p>
                <ul>
                  <li className="font-serif-text-regular">
                    Det som tilsvarer generell studiekompetanse i Norge.
                  </li>
                  <li className="font-serif-text-regular">
                    Dokumentasjon på norsk- og engelskkunnskapene dine.
                  </li>
                </ul>
                <Button
                  onClick={() =>
                    window.open(
                      "https://utdanning.no/tema/hjelp_og_veiledning/godkjenning_av_utenlandsk_videregaende_utdanning"
                    )
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Godkjenning av utenlandsk opplæring
                </Button>

                <Spacer />
              </ExpandableField>
            </div>
          </Filter>
        </Section>
      </div>
    </div>
  );
}

export default VeienTilFagbrev;
