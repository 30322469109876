import React from "react";
import ReactGa from "react-ga";

const useAnalyticsEventTracker = (category = "Veien til fagbrev") => {
  const eventTracker = (action = "Button clicked", label = "Clicked") => {
    ReactGa.event({ category, action, label });
  };
  return eventTracker;
};

export default useAnalyticsEventTracker;
