import React from 'react';

import styles from '../../sass/general/home.module.scss';
import "../../sass/style.scss";
import { Link } from "react-router-dom";


import { Breadcrumbs, Button } from '@utdanningno/designsystem-react';


import SmallCard from '../../components/card';

import img1 from '../../assets/images/Jobbkart.png';
import img2 from '../../assets/images/Interessesiden.png';
import img3 from '../../assets/images/Karriereveiledning.png';
import { ReactComponent as Group } from '../../assets/group.svg';

export default function Home() {
  return (
      <div className={styles.wrapper}>
      <div className={styles.main}>
        <Breadcrumbs>
          <a href='https://utdanning.no'>Forside</a>
          <a href='https://utdanning.no/tema/hjelp_og_veiledning/videregaende_som_voksen'>Videregående som voksen</a>
          <span>Veien til fagbrev</span>
        </Breadcrumbs>
        <div className={styles.vtsLogo}>
          <div className={styles.vts}>
            <h1 className='Heading-Large'>Veien til fagbrev</h1>
            <p className='Text-Large'>Finn ut hvordan du kan få fagbrev, svennebrev eller yrkeskompetanse.</p>
            <Link to="/filter"><Button primary={true} chevron={true}>Kom i gang</Button></Link>
          </div>
          <Group/>
        </div>
        <div className={styles.cardDiv}>
          <div className={styles.yellowarea}> </div>
          <h2 className='Heading-Medium'>Usikker på hva du vil jobbe med?</h2>
          <p className='Text-Medium'>Se hvilke jobber som finnes, eller snakk med en karriereveileder.</p>
          <div className={styles.cardLayout}>
            <SmallCard 
              title='Jobbkartet'
              undertitle='Se hvor jobbene finnes' 
              imgsource={img1} 
              body='Se hvor mange som jobber i et yrke, og hvordan yrket utvikler seg.' 
              label='Gå til Jobbkartet'
              href='https://utdanning.no/jobbkartet'
            />
            <SmallCard 
              undertitle='Utforsk interesser'
              title='Interessesiden' 
              imgsource={img3} 
              body='Fortell hva du er interessert i eller hva du kan, og se hva som passer for deg.' 
              label='Gå til Interessesiden'
              href='https://utdanning.no/interessesiden'
            />
            <SmallCard 
              undertitle='Gratis og anonymt'
              title='Karriereveiledning' 
              imgsource={img2} 
              body='Få hjelp til å finne informasjon, eller bli kjent med mulighetene dine.' 
              label='Gå til karriereveiledning'
              href='https://karriereveiledning.no'
            />
          </div>
        </div>
      </div>
      </div>
  );

};