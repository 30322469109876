import get from "lodash.get";

export class PreviewObject {
  constructor(tittle, regionalName, introText, descriptionOfYears, totalYears, path, contactInfo, phone, email, link, county, info, forwardPreviewLink, arrangementLink) {
    this.tittle = tittle;
    this.regionalName = regionalName;
    this.introText = introText;
    this.descriptionOfYears = descriptionOfYears;
    this.totalYears = totalYears;
    this.path = path;
    this.contactInfo = contactInfo;
    this.phone = phone;
    this.email = email;
    this.link = link;
    this.county = county
    this.info = info
    this.forwardPreviewLink = forwardPreviewLink;
    this.arrangementLink = arrangementLink;
  }

  static createFromApiResponse(data) {
    const properData = data.data['hydra:member']?.[0];
    const tittle = get(properData, 'tittel', '-');
    const regionalName = get(properData, 'regionalt_navn', null);
    const introText = get(properData, 'ingress', null);
    const descriptionOfYears = get(properData, 'vei_beskrivelse', []);
    const totalYears = get(properData, 'vei_varighet', null);
    const path = get(properData, 'vei', null);
    const contactInfo = get(properData, 'kontakt[0].info', null);
    const phone = get(properData, 'kontakt[0].telefon', undefined) || null;
    const email = get(properData, 'kontakt[0].epost', undefined) || null;
    const link = get(properData, 'kontakt[0].lenke', undefined) || null;
    const county = get(properData, 'fylke', '-');
    const info = get(properData, 'info', '');
    const forwardPreviewLink = window.location.href;
    const arrangementLink = get(properData, 'ordningslenke', null);


    return new PreviewObject(tittle, regionalName, introText, descriptionOfYears, totalYears, path, contactInfo, phone, email, link, county, info, forwardPreviewLink, arrangementLink)
  }
};
