import React from 'react';
import PageContainer from "../PageContainer";
import Sidebar from "../Sidebar";
import PreviewContent from "./PreviewContent";
import {useParams} from "react-router-dom";
import {useFetchSecretPreview} from "./use-fetch-secret-preview";
import Section from "../../components/section";
import Loader from "./Loader";

const SecretPreview = () => {
  const params = useParams();
  const { isLoading, data, isError } = useFetchSecretPreview(params?.uuid, params?.rid);

  const getContent = () => {
    if(isLoading) {
      return <Loader/>
    }

    if(isError) {
      return <span>Det oppstod en feil. Prøv igjen senere</span>
    }

    if(data) {
      return <PreviewContent data={data || {}}/>
    }
  }
  return (
    <PageContainer>
      <div className='main'>
        <Section width="wide" key="results" className='secret-preview'>
          <h1 className="font-serif secret-preview__header">Veien til fagbrev – <strong>forhåndsvisning</strong></h1>
          {getContent()}
        </Section>
      </div>
      <Sidebar/>
    </PageContainer>
  );
};

export default SecretPreview;
