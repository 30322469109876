import React, { useState } from "react";
import Spacer from "../../components/spacer";
import Button from "../../components/button";
import Explaination from "../../components/infoyear-explaination";
import InfoYear from "../../components/infoyear";
import { yearText, AAR_REGEX, copyUrl } from "../../utils/helpers";
import ExpandableField from "../../components/expandable-field";
import { ReactComponent as IconExternal } from "../../assets/external.svg";
import { ReactComponent as CopyIcon } from "../../assets/copy.svg";

const InfoItem = ({ title, content }) => (
  <div className="preview-content__info-item">
    <span className="text-xl">{title}</span>
    <p className="text-xl">{content}</p>
  </div>
);

const PreviewContent = ({
  data: {
    regionalName,
    introText,
    email,
    link,
    phone,
    contactInfo,
    descriptionOfYears,
    totalYears,
    path,
    tittle,
    county,
    arrangementLink,
    forwardPreviewLink,
    info,
  },
}) => {
  const [copied, setCopied] = useState(false);
  const isInternalLink =
    arrangementLink && arrangementLink.includes("utdanning.no");
  return (
    <div className="preview-content">
      <p className="preview-content__text-medium font-serif">
        {`Forhåndsvisning av ${tittle}${county ? ` i ${county}.` : "."}`}{" "}
      </p>

      {forwardPreviewLink ? (
        <>
          <p className="preview-content__text-small font-serif">
            Del forhåndsvisningen med en kollega ved å kopiere lenken og lime
            den inn i en e-post.
          </p>
          <Button
            onClick={() => {
              copyUrl();
              setCopied(true);
            }}
            direction="nei"
            style={{ maxHeight: "55px" }}
            className={`preview-content__button ${
              copied ? "border-success" : ""
            }`}
          >
            {copied
              ? "Lenken er kopiert til din utklippstavle"
              : "Kopier lenken til denne forhåndsvisningen"}
            <CopyIcon />
          </Button>
        </>
      ) : null}

      <Spacer large />

      <ExpandableField label={tittle} desc={regionalName} id={tittle} open>
        <p
          className="text-xl font-serif"
          dangerouslySetInnerHTML={{ __html: introText }}
        ></p>
        <Spacer />

        <Explaination desc={descriptionOfYears} total={totalYears} />

        <div className="preview-content__path">
          {path !== null &&
            Object.entries(path)
              .filter(([key, value]) => {
                return AAR_REGEX.test(key);
              })
              .sort()
              .map(([key, type]) => {
                return (
                  <InfoYear
                    key={key.match(AAR_REGEX)[1]}
                    type={type}
                    title={yearText(key.match(AAR_REGEX)[1])}
                    text="Opplæring i vidergående skole"
                  />
                );
              })}
        </div>

        <p
          className="text-xl font-serif"
          dangerouslySetInnerHTML={{ __html: info }}
        ></p>
        <Spacer large />

        {arrangementLink ? (
          <Button
            onClick={() => window.open(arrangementLink)}
            target="_blank"
            rel="noopener noreferrer"
            direction={isInternalLink ? "next" : null}
          >
            Les mer om veien
            {isInternalLink ? null : (
              <IconExternal className="icon secret-preview__link-icon" />
            )}
          </Button>
        ) : null}

        <Spacer large />

        <h4 className="text-xl font-serif">Kontaktinformasjon</h4>
        <Spacer large />

        <div className="preview-content__divider" />
        <Spacer large />

        <span className="text-xl">{contactInfo}</span>

        <Spacer large />
        <InfoItem title="Telefon" content={phone || "-"} />
        <Spacer large />
        <InfoItem
          title="Epost"
          content={
            email ? (
              <a href="mailto:email" target="_blank">
                {email}
              </a>
            ) : (
              "-"
            )
          }
        />
        <Spacer large />
        <InfoItem
          title="Nettsider"
          content={
            link ? (
              <a href={link} target="_blank">
                {link}
              </a>
            ) : (
              "-"
            )
          }
        />
      </ExpandableField>
    </div>
  );
};

export default PreviewContent;
