import React, { useContext, useState } from "react";
import { getCertificateCounties, getCertificatesByCounties, searchCertificates } from "../utils/certificates";

import Section from "../components/section";
import SearchContext from "../components/search";
import SearchAutocomplete from "../components/search-autocomplete";

function Examples () {
  const {search, setSearch} = useContext(SearchContext);
  const [searchResults, setSearchResults] = useState([]);

  function selectTerm(item) {
    setSearch({...search, searchTerm: '', terms:[...search.terms, item.title]});
    setSearchResults([]);
  }

  function removeTerm(index) {
    const terms = search.terms;
    terms.splice(index, 1);
    setSearch({...search, terms});
    return false;
  }

  return (
    <div className="page-layout">
      <div className="page-layout__main">
        <Section width="wide">
          <div>
            <p>Get all counties (fylke)</p>
            <ul>
              {getCertificateCounties().map(county => (
                <li key={county.id}>{county}</li>
              ))}
            </ul>
          </div>

          <div>
            <p>Search certificates (test)</p>
            <ul>
              {searchCertificates('test').map(certificate => (
                <li key={certificate.id}>{certificate.id} {certificate.title}</li>
              ))}
            </ul>
          </div>

          <div>
            <p>Get certificates by county (Nord-Norge)</p>
            <ul>
              {getCertificatesByCounties('Nord-Norge').map(certificate => (
                <li key={certificate.id}>{certificate.id} {certificate.title}</li>
              ))}
            </ul>
          </div>

          <div>
            <p>Search certificates</p>
            <SearchAutocomplete
              search={searchCertificates}
              onResults={setSearchResults}
            />
            <ul>
              {searchResults.map(item => (
                <li onClick={e => {e.preventDefault(); e.stopPropagation(); selectTerm(item)}} key={item.id}>{item.title}</li>
              ))}
            </ul>
            <p>Selected terms</p>
            {search && (
              <ul>
                {search.terms.map((term, i) => (
                  <li>
                    {term}&nbsp;
                    <a onClick={() => removeTerm(i)}>❌</a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </Section>
      </div>
    </div>
  );
}

export default Examples;