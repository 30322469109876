import React from "react";
import "./styles.scss";

export default function Spacer({ small, medium, large, xlarge, ...props }) {
  const classes = small
    ? "spacer-small"
    : medium
    ? "spacer-medium"
    : large
    ? "spacer-large"
    : xlarge
    ? "spacer-xlarge"
    : "spacer-medium";
  return (
    <div className={["spacer", classes].join(" ").trim()} {...props}></div>
  );
}
