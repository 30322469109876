import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";

import { ReactComponent as IconPlus } from "../../assets/plus.svg";
import { ReactComponent as IconChevron } from "../../assets/chevron-down.svg";
import useAnalyticsEventTracker from "../GA-tracking/useAnalyticsEventTracker";
import "./styles.scss";
import useAnalytics4Tracker from "../GA-tracking/useAnalytics4Tracker";
import SearchContext from "../search";

export default function ExpandableField({
  label,
  desc,
  labelOpen,
  open,
  children,
  alternative,
  className,
  id,
  option,
  ...props
}) {
  const { search, setSearch } = useContext(SearchContext);
  

  const idnew = id.replace(/[øæå]/g,"a");
  
  const [isOpen, setIsOpen] = useState( window.location.href.includes(idnew) || false);

  function updateURL(id = ""){
    const params = new URLSearchParams();
    if(id != "Alle"){
      Object.entries(search).map(([key,value])=>{
        if(typeof value === 'string' && value != (undefined || null || "")){
          params.append(key, value);
        }
        else if(typeof value === 'object' && value != null &&value != undefined && value!= ""){
          if(key == "occupations"){
            Object.entries(value).map(([key,value])=>{
            if(value!=undefined && value.id != id){
              params.append("fag", value.name)
            }
          })}
          else if(key == "vei")Object.entries(value).map(([key,value])=>{
            if(value!=undefined){
              params.append("vei", value)
            }
          })
        }
      })
    }
    window.location.hash = `/filter?${params.toString()}`;
  }
  
  useEffect(()=>{
    if(search.vei == undefined){
      search.vei = [];
    }
  })
  
  
  const handleToggle = () =>{
    let newvei = search.vei;
    
    if(window.location.hash.includes(idnew)){
      const index = newvei.indexOf(idnew);
      const x = newvei.splice(index, 1)
      setSearch({...search, vei: newvei})
      updateURL()
    }else{
      newvei.push(idnew);
      setSearch({...search, vei: newvei})
      updateURL()
    }
    setIsOpen(!isOpen)
  };

  const classIsOpen = (isOpen || window.location.hash.includes(id)) ? "is-open" : "";
  const classAlt = alternative ? "expandable-field--alt" : "";

  const getEventTracker = useAnalyticsEventTracker(label);
  const getAnalytics4Tracker = () => useAnalytics4Tracker(label);

  return (
    <>
      <div
        className={["expandable-field", classIsOpen, classAlt, className]
          .join(" ")
          .trim()}
        {...props}
      >
        <button
          type="button"
          className="expandable-field__toggle"
          onClick={(e) => {
            handleToggle(e);
            getEventTracker(label);
            getAnalytics4Tracker();
          }}
        >
          {alternative ? (
            <IconChevron className="icon-chevron" />
          ) : (
            <IconChevron className="icon-chevron-expandable" />
          )}

          {option ? (
            <h3 className={className}>{labelOpen ? labelOpen : label}</h3>
          ) : (
            <h2 className={className}>{labelOpen ? labelOpen : label}</h2>
          )}
        </button>
        <div className="desc font-serif-text-regular">{desc}</div>
        {isOpen && (
          <div className="expandable-field__content font-serif-text-regular">
            {children}
          </div>
        )}
      </div>
    </>
  );
}

ExpandableField.propTypes = {
  label: PropTypes.string.isRequired,
  labelOpen: PropTypes.string,
  open: PropTypes.bool,
  id: PropTypes.string,
  alternative: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
};
