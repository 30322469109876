// noinspection DuplicatedCode

import { useContext } from "react";
import ExpandableField from "../../components/expandable-field";
import Spacer from "../../components/spacer";
import Explaination from "../../components/infoyear-explaination";
import Button from "../../components/button";
import Contact from "../../components/contact-information";
import InfoYear from "../../components/infoyear";
import { ReactComponent as IconExternal } from "../../assets/external.svg";

import { yearText, AAR_REGEX } from "../../utils/helpers";

import SearchContext from "../../components/search";

function Option({ option, className, setOption }) {
  const { search } = useContext(SearchContext);
  const {
    title,
    path,
    introtext,
    text,
    descriptionOfYears,
    totalYears,
    arrangementLink,
    customName,
    county,
    beskrivelse,
  } = option;

  const isInternalLink =
    arrangementLink && arrangementLink.includes("utdanning.no");

  return (
    <ExpandableField
      label={title}
      id={title.split(" ")[0]}
      className={className}
      desc={beskrivelse}
      option={setOption}
    >
      <p
        className="font-serif-text-regular"
        dangerouslySetInnerHTML={{ __html: introtext }}
      ></p>

      {customName ? (
        <p>
          I {county} kalles ordningen for <b>{customName}</b>.
        </p>
      ) : null}

      <Spacer large />

      <Explaination desc={descriptionOfYears} total={totalYears} />

      {(typeof path === "object" && !!path) ? (
        Object.entries(path)
          .filter(([key, value]) => {
            return AAR_REGEX.test(key);
          })
          .sort()
          .map(([key, type]) => {
            return (
              <InfoYear
                key={key.match(AAR_REGEX)[1]}
                type={type}
                title={yearText(key.match(AAR_REGEX)[1])}
                text="Opplæring i vidergående skole"
              />
            );
          })
      ) : (
        <></>
      )}

      <p
        className="font-serif-text-regular"
        dangerouslySetInnerHTML={{ __html: text }}
      ></p>

      <Spacer large />

      {arrangementLink ? (
        <Button
          onClick={() => window.open(arrangementLink)}
          target="_blank"
          rel="noopener noreferrer"
          direction={isInternalLink ? "next" : null}
        >
          Les mer om veien
          {isInternalLink ? null : (
            <IconExternal className="icon secret-preview__link-icon" />
          )}
        </Button>
      ) : null}

      <Spacer />

      {/* Showing contact information based on selected county -  Info from Veier API*/}
      <Spacer large />
      <Contact county={search.valgtFylke} title={title} />
    </ExpandableField>
  );
}

export default Option;
