import React from "react";
import PropTypes from "prop-types";

import RadioItem from "./radio-item";

import "./styles.scss";

export default function Radio({
  name,
  options,
  direction,
  className,
  labelledby,
  ...props
}) {
  const directionClass = `input-radio--${direction}`;

  return (
    <div
      className={["input-radio", directionClass, className].join(" ").trim()}
    >
      {options.map(({ label, value}, idx) => {
        const keyId = `${name}-${idx}`;
        const id = `${name}-${value}`;
        return (
          <div key={keyId} className="input-radio__item">
            <RadioItem
              id={id}
              name={name}
              label={label}
              value={value}
              labelledby={labelledby}
              {...props}
            />
          </div>
        );
      })}
    </div>
  );
}

Radio.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  className: PropTypes.string,
};

Radio.defaultProps = {
  direction: "horizontal",
  options: [
    { label: "Yes", value: "ja" },
    { label: "No", value: "nei" },
  ],
  unselect: false,
};
