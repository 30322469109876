import React from "react";

import "./style.scss";



export default function SmallCard({
  title,
  short,
  undertitle,
  body,
  label,
  href,
  imgsource,
}: SmallCardProps) {
  return (
    <div class="aktuelt-card-container">
        <a className="intervjuClass" href={href}>
          <div class="card" tabindex="-1">
            <img src={imgsource} class="imgClass" height="200" width="200" alt="placeholder" />
            <div class="overlapBox imgClass"></div>
            <div class="content">
              <p className="Text-Small">{undertitle}</p>
              <h3 class="Heading-Regular">
                {title}
              </h3>
              <br/>
              <p class="Text-Regular">{body}</p>
              
             
              
            </div>
            <div class="card-right-arrow stretched-link arrow-link">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
                <path d="M20.2 8.92379L1 9.07617" stroke="#333333" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.8828 17L20.959 8.9238L13.0352 1" stroke="#333333" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
        </a>
      </div>
  );
}