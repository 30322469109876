import React from "react";
import PropTypes from "prop-types";
import useAnalyticsEventTracker from "../../GA-tracking/useAnalyticsEventTracker";
import useAnalytics4Tracker from "../../GA-tracking/useAnalytics4Tracker";

export default function RadioItem({
  label,
  id,
  value,
  name,
  selected,
  setRadio,
  setRadioReset,
  labelledby,
  unselect,
  ...props
}) {
  const getEventTracker = useAnalyticsEventTracker(name);
  const getAnalytics4Tracker = (value) => useAnalytics4Tracker(name, value);

  function handleChange(e) {
    const newValue = e.target.value;
    getEventTracker(newValue);
    getAnalytics4Tracker(newValue);
    return setRadio?.(newValue);
  }

  function handleClick(e) {
    if (unselect && selected?.toString() === e.target.value) {
      getEventTracker(e.target.value);
      getAnalytics4Tracker(e.target.value);
      return setRadioReset?.(e.target.value);
    } 
  }

  function handleKeyDown(e) {
    if (
      unselect &&
      e.keyCode === 32 &&
      selected?.toString() === e.target.value
    ) {
      e.preventDefault();
      getEventTracker(e.target.value);
      getAnalytics4Tracker(e.target.value);
      return setRadioReset?.(e.target.value);
    }
  }

  return (
    <>
      <input
        type="radio"
        name={name}
        id={id}
        checked={value === selected}
        value={value}
        onClick={(e) => handleClick(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        onChange={(e) => handleChange(e)}
        aria-labelledby={labelledby}
        {...props}
      />
      <label htmlFor={id}>
        <span className="input-radio__copy">{label}</span>
      </label>
    </>
  );
}

RadioItem.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setRadio: PropTypes.func,
  setRadioReset: PropTypes.func,
  unselect: PropTypes.bool,
};
