import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Button from "../button/index.js";

import "./styles.scss";

export default function Filter({ buttonText, open, children, ...props }) {
  const [filterIsOpen, setFilterIsOpen] = useState(open || false);

  const isOpen = filterIsOpen ? "is-open" : "is-closed";

  return (
    <div className={["filter", isOpen].join(" ")}>
      <Button
        className="filter__toggle"
        onClick={() => setFilterIsOpen(!filterIsOpen)}
        showFilter={true}
        direction="none"
        active={filterIsOpen}
        aria-describedby="speech-bubble"
      >
        {filterIsOpen ? (
          <span className="font-serif-text-regular" aria-label="skjul valg"> Skjul valg </span>
        ) : (
          <span className="font-serif-text-regular" aria-label="vis valg"> Vis flere valg </span>
        )}
      </Button>

      <div className="filter__body">
        <div className="filter__summary">
          <div className="filter__details">{children}</div>
        </div>
      </div>
    </div>
  );
}

Filter.propTypes = {
  points: PropTypes.object,
};

Filter.defaultProps = {
  points: {},
  buttonText: "Vis filter",
  view: "buttonclass",
};
