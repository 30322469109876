import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect} from "react";
import LoadingWrapper from "../../components/loading-wrapper";
import { getOccupations, searchOccupations } from "../../utils/occupations";
import SearchContext from "../search";
import SearchAutocomplete from "../search-autocomplete";


import "./styles.scss";

export default function SearchOccupation() {
  const { search, setSearch } = useContext(SearchContext);
  const {isLoading, data: occupations} = useQuery(['occupations'], () => getOccupations())
 

  function selectItem(item, refresh =true) {
    !search.occupations && (search.occupations = []);
    search.occupations.push(item);
    setSearch({...search});
    if(refresh){
      const params = new URLSearchParams();
      const url = Object.entries(search).map(([key,value])=>{
        if(typeof value === 'string' && value != null &&value != undefined && value!= ""){
          params.append(key, value);
        }
        if(typeof value === 'object' && value != null &&value != undefined && value!= ""){
          if(key == "occupations"){
            Object.entries(value).map(([key,value])=>{
              params.append("fag", value.name)
            })
          }else if (key == "vei"){
            Object.entries(value).map(([key,value])=>{
              params.append("vei", value)
            })
          }
        }
      })
      window.location.hash = `filter/?${params.toString()}`; 
    }
    
    
  }
  
  
  return (
    <div className="search-occupation">
      <LoadingWrapper isLoading={isLoading}>
        {occupations && (
          <SearchAutocomplete  search={(str) => searchOccupations(occupations, str)} onSelect={selectItem} />
        )}
      </LoadingWrapper>
    </div>
  );
 
}

SearchOccupation.defaultProps = {
  small: false,
  medium: true,
  large: false,
};
