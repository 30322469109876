import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { ReactComponent as IconQuestionCircle } from "../../assets/question-circle.svg";
import { ReactComponent as IconCross } from "../../assets/cross-small.svg";
import useAnalyticsEventTracker from "../GA-tracking/useAnalyticsEventTracker.js";
import useAnalytics4Tracker from "../GA-tracking/useAnalytics4Tracker";
import "./styles.scss";

export default function Tooltip({
  label,
  title,
  description,
  children,
  open,
  className,
}) {
  const [showTooltip, setShowTooltip] = useState(open);

  const classTooltipLabel = label ? "tooltip-label" : "tooltip-label--no-label";

  const handleToggle = () => setShowTooltip(!showTooltip);

  const getEventTracker = useAnalyticsEventTracker(
    "Tooltip (spørsmålstegn-ikon)"
  );
  const getAnalytics4Tracker = () =>
    useAnalytics4Tracker(title, "Tooltip (spørsmålstegn-ikon)");

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setShowTooltip(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [setShowTooltip]);

  return (
    <div className="tooltip-wrapper">
      <div className={[classTooltipLabel, className].join(" ").trim()}>
        {label ? label : children}
        <button
          type="button"
          className="tooltip-label__toggle"
          onClick={() => {
            handleToggle();
            getEventTracker(title);
            getAnalytics4Tracker();
          }}
        >
          <span className="visuallyhidden">
            {showTooltip ? "Lukk hjelpetekst" : "Åpne hjelpetekst"}
          </span>
          <IconQuestionCircle className="icon-question" />
        </button>
      </div>
      {!label || children}
      {showTooltip && (
        <div className="tooltip font-serif-text-regular">
          {description && (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </div>
      )}
    </div>
  );
}

Tooltip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  open: PropTypes.bool,
};

Tooltip.defaultProps = {
  open: false,
};
