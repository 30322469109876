import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

export default function SpeechBubble({ title, children, ...props }) {
  return (
    <div className="bubble" {...props} id="speech-bubble">
      {title && (
        <div className="bubble__title">
          <h1>{title}</h1>
        </div>
      )}
      {children && <div className="bubble__description">{children}</div>}
    </div>
  );
}

SpeechBubble.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

SpeechBubble.defaultProps = {};
