import { useQuery } from '@tanstack/react-query'
import axios from "axios";
import {PreviewObject} from "./PreviewObject";

const fetch = async (uuid, rid) => {
  if(!window.CONFIG_CERTIFICATE_SELECTOR) {
    console.error('API URL not defined - CONFIG_CERTIFICATE_PREVIEW_SELECTOR');
    return
  }
  const fetchUrl = [];
  fetchUrl.push(`${window.CONFIG_CERTIFICATE_SELECTOR}/${uuid}?`);
  if (rid) {
    fetchUrl.push(`resourceVersion=id:${rid}&`);
  }
  fetchUrl.push(`include=field_kontakt_info_pid,field_fylke_tid,field_kommune_tid`);
  const data = await axios(fetchUrl.join(''));
  return PreviewObject.createFromApiResponse(data);
}

export const useFetchSecretPreview = (uuid, rid) => {
  return useQuery([`preview-${uuid}-${rid}`], () => fetch(uuid, rid))
}
