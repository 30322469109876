import React, { useState } from "react";
import PropTypes from "prop-types";
import useAnalyticsEventTracker from "../../GA-tracking/useAnalyticsEventTracker";
import useAnalytics4Tracker from "../../GA-tracking/useAnalytics4Tracker";
import "./styles.scss";

export default function Select({
  name,
  value,
  setValue,
  options,
  className,
  placeholder,
  ...props
}) {
  const [isInvalidClass, setIsInvalidClass] = useState("");
  const handleValidation = (e) =>
    setIsInvalidClass(!e.target.validity.valid ? "is-invalid" : "");

  function handleChange(e) {
    getEventTracker(e.target.value);
    getAnalytics4Tracker(e.target.value);
    return setValue?.(e.target.value);
  }

  const getEventTracker = useAnalyticsEventTracker(
    "Velg fylke eller kommune(Nedtrekksliste)"
  );
  const getAnalytics4Tracker = (value) =>
    useAnalytics4Tracker("Velg fylke eller kommune(Nedtrekksliste)", value);

  return (
    <div
      className={["input-select", isInvalidClass, className].join(" ").trim()}
    >
      <select
        id={props.id || name}
        name={name}
        value={value}
        onBlur={handleValidation}
        onChange={(e) => {
          handleChange(e);
        }}
        {...props}
      >
        {options.map((option) => {
          const optionKey = `option-${option.label.replace(/\s/g, "")}`;

          if ("children" in option) {
            return (
              <optgroup key={optionKey} label={option.label}>
                {option.children.map((childOption) => {
                  const childOptionKey = `option-${childOption.label.replace(
                    /\s/g,
                    ""
                  )}`;
                  return (
                    <option
                      key={childOptionKey}
                      value={childOption.value || ""}
                    >
                      {childOption.label}
                    </option>
                  );
                })}
              </optgroup>
            );
          }
          return (
            <option key={optionKey} value={option.value || ""}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  className: PropTypes.string,
};
