import "react-app-polyfill/ie11";
import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga";
import "./sass/style.scss";
import App from "./pages";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://cfa68b7e7678e954e24d25a1f630686f@o4505957043798016.ingest.sentry.io/4506350814167040",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],
  denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      // Lingit
      /localhost:8036/i,
  ],

  // https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
  beforeSend(event, hint) {
    if (
        event?.request?.headers?.['user-agent'] &&
        event?.request?.headers?.['user-agent'].match(/siteimprove/i)
    ) {
        return null;
    }

    const error = hint.originalException;
    if (error) {
        if (
            error.response &&
            error.response.status &&
            error.response.status === 404
        ) {
            return null;
        }
        if (error.message && error.message.match(/timeout/i)) {
            return null;
        }
    }
    return event;
  },

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Add class 'mouse-click' to document body to prevent :focus style to display on click
document.body.addEventListener("mousedown", () => {
  document.body.classList.add("mouse-click");
});

// Remove class 'mouse-click' when keyboard 'Tab' is used
document.body.addEventListener("keydown", (e) => {
  if (e.key === "Tab") {
    document.body.classList.remove("mouse-click");
  }
});

const TRACKING_ID = "UA-658994-9";
ReactGA.initialize(TRACKING_ID);

const queryClient = new QueryClient();
const root = document.getElementById("root");

createRoot(root).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);
