import React from "react";

import "./styles.scss";

function Explaination({ desc, total }) {
  return (
    <div className="explaination">
      {desc &&
        desc.map((item, i) => {
          return item.type === "skole" ? (
            <p key={i} className="school text-lg">
              Opplæring på skole ({item.length})
            </p>
          ) : (
            <p key={i} className="work text-lg">
              Opplæring i bedrift ({item.length})
            </p>
          );
        })}

      {total ? (
        <p className="font-serif-text-regular font-bold">
          Varighet: {total.toString()}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Explaination;
