import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Spacer from "../spacer";

import "./styles.scss";

export default function InfoYear({ title, text, type, ...props }) {
  const [showText, setShowText] = useState(
    "Opplæring i bedrift og videregående skole"
  );

  useEffect(() => {
    if (type == null) {
      setShowText("Denne veien finnes ikke");
    } else if (type == "skole_bedrift") {
      setShowText("Opplæring i bedrift og videregående skole");
    } else if (type == "skole") {
      setShowText("Opplæring i videregående skole");
    } else if (type == "bedrift") {
      setShowText("Opplæring i bedrift");
    } else if (type == "bedrift_variabel") {
      setShowText("Opplæring i bedrift (gjelder noen fagbrev)");
    } else if (type == "bedrift_halv") {
      setShowText("Halvår hos bedrift");
    }
  });

  const isType = `${type}`;

  return (
    <>
      <Spacer />
      <div className={["info-year", isType].join(" ").trim()}>
        <p className="font-bold font-serif-text-regular">{title}</p>
        <p className="font-serif-text-regular">{showText}</p>
      </div>
      <Spacer small />
    </>
  );
}

InfoYear.propTypes = {
  school: PropTypes.bool,
  work: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
};

InfoYear.defaultProps = {
  text: "Showing info about first year",
  title: "First year",
  school: false,
  work: true,
};
