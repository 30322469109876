/**
 * Certificates
 * @module utils/certificates
 */

function normalizeCertificateData(data) {
  const {
    id,
    tittel: title,
    oprettet: created,
    oppdatert: updated,
    vei: path,
    fylke: county,
    kommune: comune,
    info: text,
    ingress: introtext,
    vises_under_25: is_twentyfive,
    fagbrev_fra_foer: has_certificate,
    kontakt: contact,
    vei_beskrivelse: descriptionOfYears,
    vei_varighet: totalYears,
    regionalt_navn: customName,
    ordningslenke: arrangementLink,
    beskrivelse: beskrivelse,
  } = data;

  return {
    id,
    title,
    created,
    updated,
    path,
    descriptionOfYears,
    totalYears,
    county,
    comune,
    introtext,
    text,
    is_twentyfive,
    has_certificate,
    contact,
    customName,
    arrangementLink,
    beskrivelse,
  };
}

/**
 * Returns a structure with all the certificates
 *
 * @returns {object} a key-value object with all the certificates by id
 */
export async function getCertificates() {
  if (!window.CONFIG_CERTIFICATE_SELECTOR) {
    console.error("API URL not defined.");
    return [];
  }

  const response = await fetch(`${window.CONFIG_CERTIFICATE_SELECTOR}`);
  const data = await response.json();
  // JSON+LD appends extra keys to the object, ignore them
  const { "hydra:member": docs } = JSON.parse(
    JSON.stringify(data, (k, v) => (k.startsWith("@") ? undefined : v))
  );

  const certificates = [];

  // Leave if no more results to parse
  if (!docs || !docs.length) {
    return certificates;
  }
  // Parse results into a normalized array
  docs.forEach((doc) => {
    const certificate = normalizeCertificateData(doc);
    return certificate && certificates.push(certificate);
  });

  return certificates;
}

/**
 * Searches the certificates for a search object.
 *
 * @param {array} certificates list of certificates to filter from
 * @param {array} names the names to look for
 * @returns {array} the list of results
 */
export function searchCertificatesByName(certificates = [], names) {
  // const certificates = await getCertificates();

  return Object.values(certificates).filter((item) =>
    names.includes(item.title)
  );
}

/**
 * Check if commune from certificates is the same as commune from search.
 *
 * @param {array} communes list of communes
 * @param {array} valgtKommune the communes to look for
 * @returns {boolean} return true if communes.includes(valgtKommune)
 */
function checkCommune(communes = [], valgtKommune) {
  if (communes != null && communes != undefined) {
    return communes.includes(valgtKommune);
  }
}

/**
 * Searches the certificates for a search object.
 *
 * @param {array} certificates list of certificates to filter from
 * @param {object} search the search object
 * @returns {array} the list of results
 */
export function searchCertificates(certificates = [], search) {
  // Algoritmen er mer komplisert enn nødvendig fordi tomme verdier kan være "" eller null
  // Kommune kan ikke velges uten at fylke er valgt
  // For stedsvalg:
  // 1: Hvis ikke valgt fylke:
  //  - Vis veier som ikke er tilknyttet fylke
  // 2: Hvis valgt fylke:
  // 2.1: Hvis ikke valgt kommune;
  // - Vis veier tilknyttet valgt fylke og alle kommunene
  // 2.2: Hvis valgt kommune:
  // - Bare vis veier som er tilknyttet valgt kommune
  // const certificates = await getCertificates();

  const placeFiltered = certificates.filter((item) => {
    const retval = !search.valgtKommune
      ? search.valgtFylke === item.county ||
        (!search.valgtFylke && !item.county)
      : checkCommune(item.comune, search.valgtKommune) ||
        (!search.valgtKommune && !item.comune);
    return retval;
  });

  // is_twentyfive betyr over 25
  // search.erTjuefem betyr at "Er du 25 år eller eldre?" er valgt
  const twentyFiveFiltered = placeFiltered.filter(
    (item) =>
      search.erTjuefem === "" ||
      search.erTjuefem === "ja" ||
      (search.erTjuefem === "nei" && item.is_twentyfive === false) ||
      (search.erTjuefem === null && item.is_twentyfive === null)
  );
  const certificateFiltered = twentyFiveFiltered.filter(
    (item) =>
      search.harErfaring === "" ||
      (search.harErfaring === "ja" && item.has_certificate === true) ||
      (search.harErfaring === "nei" && item.has_certificate === false) ||
      (search.harErfaring === null && item.has_certificate === null)
  );
  return certificateFiltered;
  /*
  // @todo search.hasAbroadEducation
  // @todo search.harErfaring
*/
}

/**
 * Gets certificates filtered by counties.
 *
 * @param {array} certificates list of certificates to filter from
 * @param {array} counties the input array of counties
 * @returns {array} the list of results
 */
export function getCertificatesByCounties(certificates = [], counties) {
  return certificates.filter((certificate) =>
    counties.includes(certificate.county)
  );
}

/**
 * Gets contact information filtered by counties and title.
 *
 * @param {array} certificates list of certificates to filter from
 * @param {array} counties used to only get information for specific certificates
 * @param {array} title used to only get information for specific certificates
 * @returns {array} the list of results
 */
export function getContactInformationByCounties(
  certificates = [],
  counties,
  title
) {
  const contacts = [];

  if (certificates) {
    certificates.map((certificate) => {
      if (certificate.county == counties && certificate.title == title) {
        if (certificate.contact) {
          certificate.contact.map((contactInfo) => {
            contacts.push(contactInfo);
          });
        }
      }
    });
  }
  return contacts;
}

/**
 * Gets all counties from certificates.
 *
 * @param {array} certificates list of certificates to filter from
 * @returns {array} the list of counties
 */

export function getCertificateCounties(certificates = []) {
  return certificates.reduce(function (result, certificate) {
    certificate.county &&
      !result.includes(certificate.county) &&
      result.push(certificate.county);
    return result;
  }, []);
}

/**
 * Gets communes by county.
 * @param {array} certificates list of certificates to filter from
 * @param {string} county the county to filter by
 * @returns {array} the list of communes
 */
export function getCertificateCommunesByCounty(certificates = [], county) {
  return certificates
    .filter(
      (certificates) => certificates.county === county && certificates.comune
    )
    .flatMap((certificate) => certificate.comune);
}

/**
 * Gets certificates filtered by counties by null
 *
 * @param {array} certificates list of certificates to filter from
 * @param {array} counties the input array of counties
 * @returns {array} the list of results
 */
export function getAllCounties(certificates = [], counties) {
  return certificates.filter((certificate) => certificate.county === counties);
}

/**
 * Gets certificates filtered by title
 *
 * @param {array} certificates list of certificates to filter from
 * @param {string} name the title
 * @returns {array} the list of results
 */
export async function getCertificatesByTitle(certificates = [], name) {
  return certificates.filter((certificate) => certificate.title == name);
}
