import React, { useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import Section from "../../components/section";
import Spacer from "../../components/spacer";
import PageIntroduction from "../../components/page-introduction";
import ExpandableField from "../../components/expandable-field";
import Explaination from "../../components/infoyear-explaination";
import Contact from "../../components/contact-information";
import Button from "../../components/button";
import LoadingWrapper from "../../components/loading-wrapper";
import Option from "./Option";
import Options from "./Options";
import CustomName from "../../components/custom-name";
import InfoYear from "../../components/infoyear";
import { yearText, AAR_REGEX } from "../../utils/helpers";
import SearchContext from "../../components/search";

import {
  getCertificates,
  searchCertificatesByName,
} from "../../utils/certificates";

import { getAllOptions, getOptions } from "../../utils/options";


function Results() {
  const { search } = useContext(SearchContext);
  // Some certificates should be shown inside the VGS section
  const VGSCertificateNames = ["Kryssløp", "TAF/YSK", "Vekslingsmodell"];
  const fontHeadingMedium = "font-serif-heading-medium";
  const fontHeadingRegular = "font-serif-heading-regular";

  const { isLoading, data: certificates } = useQuery(
    ["certificates"],
    getCertificates
  );
  const { isOptionsLoading, data: options } = useQuery(
    ["options"],
    getAllOptions
  );
  
  function expandableField(open) {
    return (
      <>
        <ExpandableField
          className={fontHeadingMedium}
          label="Videregående (skole + bedrift)"
          desc="Opplæring i skole eller bedrift"
          id="OpplaringSkolebedrift"
          open={open}
        >
          <LoadingWrapper isLoading={isOptionsLoading}>
            {options &&
              getOptions(options, search).map(
                (
                  {
                    title,
                    description,
                    introtext,
                    text,
                    link,
                    path,
                    descriptionOfYears,
                    totalYears,
                  },
                  index
                ) => {
                  return (
                    <ExpandableField
                      className={fontHeadingRegular}
                      key={index}
                      label={title}
                      id={title}
                      desc={description}
                      option={true}
                    >
                      <p
                        className="font-serif-text-regular"
                        dangerouslySetInnerHTML={{ __html: introtext }}
                      ></p>
                      <Spacer />

                      <CustomName county={search.valgtFylke} />

                      <Spacer large />

                      <Explaination
                        desc={descriptionOfYears}
                        total={totalYears}
                      />
                      {path !== null ? (
                        Object.entries(path)
                          .filter(([key]) => {
                            return AAR_REGEX.test(key);
                          })
                          .sort()
                          .map(([key, type]) => {
                            return (
                              <InfoYear
                                key={key.match(AAR_REGEX)[1]}
                                type={type}
                                title={yearText(key.match(AAR_REGEX)[1])}
                                text="Opplæring i vidergående skole"
                              />
                            );
                          })
                      ) : (
                        <></>
                      )}

                      <p
                        className="font-serif-text-regular"
                        dangerouslySetInnerHTML={{ __html: text }}
                      ></p>
                      <Spacer large />

                      <Button
                        onClick={() =>
                          window.open("https://www.vigo.no/vigo/servlet/vigo")
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Søk opptak på vigo.no
                      </Button>

                      <Spacer />

                      {link && <Button>Les mer om ordningen</Button>}

                      <Spacer large />
                      <Contact county={search.valgtFylke} title={title} />
                    </ExpandableField>
                  );
                }
              )}
          </LoadingWrapper>
          <LoadingWrapper loading={isLoading}>
            {certificates &&
              searchCertificatesByName(certificates, VGSCertificateNames).map(
                (item) => (
                  <Option
                    option={item}
                    key={item.id}
                    className={fontHeadingRegular}
                    setOption={true}
                  />
                )
              )}
          </LoadingWrapper>
        </ExpandableField>
      </>
    );
  }

  return (
    <>
      <Section width="wide" key="results">
        {search.valgtFylke != "" ? (
          <div className="small-screen-only">
            <h3 className="font-serif-heading-medium">Mulige veier</h3>
            <div className="divider" />
          </div>
        ) : null}
        {search.valgtFylke === "" ? (
          <div className="small-screen-only">
            <h2 className="font-serif-heading-medium">Nasjonale veier</h2>
            <p className="font-serif-text-medium">
              Veiene under gjelder uansett hvor du bor i Norge.
            </p>
          </div>
        ) : null}
        <div className="lg-up">
          <PageIntroduction />
          {search.valgtFylke === "" ? (
            <div>
              <h2 className="font-serif-heading-medium">Nasjonale veier</h2>
              <p className="font-serif-text-medium">
                Veiene under gjelder uansett hvor du bor i Norge.
              </p>
            </div>
          ) : null}
        </div>
        <Spacer />
        {search.occupations.length >= 1 || search.valgtFylke >= 1
          ? expandableField(true)
          : null}
        <LoadingWrapper isLoading={isLoading}>
          <Options
            exclude={VGSCertificateNames}
            startWithNumber={false}
            className={fontHeadingMedium}
          />
          {search.occupations == 0 || search.valgtFylke == 0
            ? expandableField(false)
            : null}
          <Options
            exclude={VGSCertificateNames}
            startWithNumber={true}
            className={fontHeadingMedium}
          />
        </LoadingWrapper>
      </Section>
    </>
  );
}

export default Results;
